import { Route, Routes } from 'react-router-dom';

import { useScrollToTop } from 'hooks/useScrollToTop';
import {
  AccountSecurity,
  BaselineSurveyTest,
  CheckYourEmail,
  ConfirmMFA,
  Contact,
  Dashboard,
  DataStory,
  EmailVerified,
  Enrollment,
  FormioTest1,
  FormioTest2,
  FormioTest3,
  FormioTest4,
  Join,
  Login,
  MyAccount,
  MyEducationalResources,
  MyHealthSummary,
  MyImpactWithCVR,
  NotFound,
  ResetPassword,
  ResetSuccessful,
  SetNewPassword,
  SplashPage,
  Survey,
  Verify2FA,
  VerifyEmail,
} from 'pages';
import Maintenance from 'pages/Maintenance';

import HeaderRoute from './HeaderRoute';
import MaintenanceRoute from './route.maintenance';
import PrivateRoute, { TestPages } from './route.private';

export const NHFRoutes = () => {
  useScrollToTop();
  return (
    <Routes>
      <Route element={<HeaderRoute />}>
        <Route path='/under-maintenance' element={<Maintenance />} />
      </Route>
      <Route element={<MaintenanceRoute />}>
        <Route path='/' element={<SplashPage />} />
        <Route element={<HeaderRoute />}>
          <Route element={<TestPages />}>
            <Route path='test'>
              <Route path='survey' element={<BaselineSurveyTest />} />
              <Route path='formio'>
                <Route path='1' element={<FormioTest1 />} />
                <Route path='2' element={<FormioTest2 />} />
                <Route path='3' element={<FormioTest3 />} />
                <Route path='4' element={<FormioTest4 />} />
              </Route>
              <Route path='my-health-summary' element={<MyHealthSummary />} />
              <Route
                path='my-educational-resources'
                element={<MyEducationalResources />}
              />
              <Route path='my-impact-with-cvr' element={<MyImpactWithCVR />} />
            </Route>
          </Route>
          <Route path='email-verified' element={<EmailVerified />} />
          <Route path='set-new-password' element={<SetNewPassword />} />
          <Route path='reset-password' element={<ResetPassword />} />
          <Route path='check-your-email' element={<CheckYourEmail />} />
          <Route path='contact' element={<Contact />} />
          <Route path='reset-successful' element={<ResetSuccessful />} />
          <Route element={<PrivateRoute />}>
            <Route path='join' element={<Join />} />
            <Route path='login' element={<Login />} />
            <Route path='verify-email' element={<VerifyEmail />} />
            <Route path='enrollment' element={<Enrollment />} />
            <Route path='account-security'>
              <Route index element={<AccountSecurity />} />
              <Route path='confirm' element={<ConfirmMFA />} />
            </Route>
            <Route path='homepage'>
              <Route index element={<Dashboard />} />
              <Route path='data-story' element={<DataStory />} />
              <Route path='my-account' element={<MyAccount />} />
              <Route path='my-impact-with-cvr' element={<MyImpactWithCVR />} />
              <Route path='my-health-summary' element={<MyHealthSummary />} />
              <Route
                path='my-educational-resources'
                element={<MyEducationalResources />}
              />
              <Route path='survey/:id' element={<Survey />} />
            </Route>
          </Route>
          <Route path='verify-2fa' element={<Verify2FA />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default NHFRoutes;
