import { Stack, Typography } from '@mui/material';

import Container from 'components/Enrollment/Container';
import { ReactComponent as Attention } from 'images/attention.svg';

import { Layout } from './styles';

const Maintenance = () => (
  <Stack py={{ xs: 0, sm: '50px' }}>
    <Container maxWidth='600px'>
      <Layout>
        <Stack
          spacing='10px'
          alignItems={{ xs: 'stretch', sm: 'center' }}
          flex={1}
        >
          <Stack spacing='10px' alignItems='center' flex={1}>
            <Attention />
            <Typography variant='w600h24' color='gray.80' align='center'>
              We'll be back soon!
            </Typography>
            <Typography variant='w400h14' color='gray.80' align='center'>
              <p>
                Attention: Please be aware that the NBDF will be unavailable due
                to schedule maintenance until October 10, 2024. During this
                time you will be unable to access the application. Please,
                contact{' '}
                <a href='mailto:CVR@hemophilia.org'>CVR@hemophilia.org</a> if
                you have any questions.
              </p>
              <p>Thank you</p>
            </Typography>
          </Stack>
        </Stack>
      </Layout>
    </Container>
  </Stack>
);

export default Maintenance;
