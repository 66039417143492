import { Navigate, Outlet } from 'react-router-dom';

const MaintenanceRoute = ({ children, underMaintenance = true }) =>
  underMaintenance ? (
    <Navigate to={'/under-maintenance'} replace />
  ) : children ? (
    children
  ) : (
    <Outlet />
  );

export default MaintenanceRoute;
